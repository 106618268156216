import React, { useState, useMemo } from "react";
import { Link } from "react-router-dom";

const Table = ({ data, itemsPerPage,headerLabels }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");

  // Headers derived from object keys
  const headers =  useMemo(() => data.length>0 ? Object.keys(data[0]):[], [data]);

  // Filtered items based on search query
  const filteredItems = useMemo(() => {
    return data.filter((item) => headers.some((header) => item[header].toString().toLowerCase().includes(searchQuery.toLowerCase())));
  }, [data, headers, searchQuery]);

  // Calculate total pages
  const totalPages = Math.ceil(filteredItems.length / itemsPerPage);

  // Get current items for the page
  const currentItems = useMemo(() => {
    return filteredItems.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);
  }, [filteredItems, currentPage, itemsPerPage]);

  // Handle page click
  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Handle search input change
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1); // Reset to first page on search
  };

  function donwloadPdf(report_id){
    fetch("api/download-pdf/"+report_id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    
      credentials: "include",
    })
      .then((response) => response.blob())
      .then((blob) => {
         
        const url = window.URL.createObjectURL(new Blob([blob])); // Create a URL for the Blob

          // Create a link element for the download
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'jegyzokonyv_'+report_id+'.pdf');  // Set the desired filename

          // Append the link to the body and trigger the download
          document.body.appendChild(link);
          link.click();
      })
    }


  return (
    <div>
      <div className="row" style={{justifyContent:"space-between"}}>
        <Link to="/report"><button>Új jegyzőkönyv</button></Link>
        <input type="text" className="input-control" placeholder="Keresés" value={searchQuery} onChange={handleSearchChange} />
      </div>
      {data.length>0 &&
      <div className="table" style={{justifyContent:"flex-start"}}>
        <div className="header row">
          {headers.map((header, index) => (
            (header!="is_closed" && header!="id") && <div key={index} className="header-item ">
              {headerLabels[index]}
            </div>
          ))}
          <div  className="header-item ">
              Műveletek
            </div>
        </div>

        <div className="item-list">
          {currentItems.map((item, index) => (
            <div key={index} className="item row">
              {headers.map((header) => (
               ( header!="is_closed" && header!="id") && <div key={header} className="item-field">
                  {item[header]}
                </div>
              ))}
               <div  className="item-field">
                 <Link to={"/report?reportId="+item.id}><button className="editBtn"></button></Link>
                 {item["is_closed"]}
                 {item.is_closed && <button className="pdfBtn" onClick={() => donwloadPdf(item.id)} ></button>}
                </div>
            </div>
          ))}
        </div>
      </div>
}
    {data.length>0 &&
      <div className="pagination">
        <button onClick={() => handlePageClick(currentPage - 1)} disabled={currentPage === 1}>
          {"<<"}
        </button>

        {[...Array(totalPages)].map((_, index) => (
          <button key={index} onClick={() => handlePageClick(index + 1)} className={index + 1 === currentPage ? "active" : ""}>
            {index + 1}
          </button>
        ))}

        <button onClick={() => handlePageClick(currentPage + 1)} disabled={currentPage === totalPages}>
          >>
        </button>
      </div>
}
    </div>
  );
};

export default Table;
