import React, { useContext } from "react";
import { UserContext } from "../../context/UserContext";

const Header = () => {
  const { user, logOut } = useContext(UserContext);

  return (
    <>
      {user ? (
        <div className="header-container">
          <a href="/dashboard" title="home button icons" id="home"></a>
          <span>Üdv, {user.first_name}</span>
          <button onClick={logOut} id="logout"> </button>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default Header;
